import Box from "@mui/material/Box";
import React from "react";

import CategoriesList from "../../Components/Catergories";

function CategoriesPage() {
  return (
    <Box>
      <CategoriesList />
    </Box>
  );
}

export default CategoriesPage;
